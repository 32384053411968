img {
    max-width: 100%;
    height: auto;
    max-height: 200px;
}

.card-title {
    text-align: center;
}

.invisible-link {
    color: black;
    text-decoration: none;
  }
